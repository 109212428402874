.statlist {
  margin-bottom: 20px;
  padding-left: 0;
  list-style: none;
  border-bottom: 1px solid @statlist-border-color; // #e5e5e5;
}

.statlist-link {
  position: relative;
  display: block;
  padding: 10px;
  color: @statlist-link-color; // inherit
  border-top: 1px solid @statlist-border-color; // #e5e5e5;
}

.statlist-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: block;
  background-color: @statlist-progress-bg; // #f5f5f5;
}
