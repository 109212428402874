.table-full {
  @media (max-width: @screen-sm-min) {
    margin-right: ((@grid-gutter-width / -2) - 1);
    margin-left:  ((@grid-gutter-width / -2) - 1);
  }
}

// Toolbar type thing above tables
.table-actions {
  padding-bottom: 15px;
}
