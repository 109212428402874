// Button Outline
// --------------------------------------------------

.btn-default-outline {
  .button-outline-variant(@btn-default-color);
}
.btn-primary-outline {
  .button-outline-variant(@btn-primary-border);
}
.btn-success-outline {
  .button-outline-variant(@btn-success-border);
}
.btn-info-outline {
  .button-outline-variant(@btn-info-border);
}
.btn-warning-outline {
  .button-outline-variant(@btn-warning-border);
}
.btn-danger-outline {
  .button-outline-variant(@btn-danger-border);
}
